import * as React from "react";
import Typography from "@mui/material/Typography";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  CircularProgress,
  IconButton,
  Slide,
  Stack,
} from "@mui/material";
import { LocationProps } from "../services/api.interfaces";
import { SignInUpContainer } from "../components/SignInUpContainer";
import { postVerify } from "../services/api.calls";
import CheckIcon from "@mui/icons-material/Check";
import { appInsights } from "../services/appInsights";
import { useTranslation } from "react-i18next";

const VerifyEmail: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isVerified, setIsVerified] = React.useState(false);

  const [showError, setShowError] = React.useState(false);
  const { verificationId } = useParams();
  const checkVerificationId = () => {
    setIsLoading(true);

    postVerify(verificationId)
      .then(() => {
        setIsLoading(false);
        setIsVerified(true);
      })
      .catch((error: any) => {
        setShowError(true);
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    checkVerificationId();
    appInsights.trackPageView({ name: "verifyEmail", pageType: "screen" });
    appInsights.trackEvent({ name: "verifyEmail" }, { type: "screen" });
  }, []);
  const { t } = useTranslation();

  return (
    <SignInUpContainer
      showError={showError}
      errorMessage={t("sendError") || ""}
    >
      <Slide
        direction="down"
        in={isVerified}
        mountOnEnter
        unmountOnExit
        timeout={{
          appear: 500,
          enter: 300,
          exit: 500,
        }}
      >
        <Stack
          spacing={0}
          direction="row"
          alignItems="center"
          alignContent="flex-start"
          sx={{
            color: "#0B1ECF",
            background: "#E1FDF5",
            padding: "17px 26px",
            position: "absolute",
            top: "100px",
            borderRadius: "8px",
          }}
        >
          <CheckIcon sx={{ fontSize: "35px", color: "#68F3CB" }} />
          <Typography
            variant="body2"
            component="span"
            sx={{
              color: "#0B1ECF",
            }}
          >
            {t("emailIsVerified")}
            <br />
          </Typography>
        </Stack>
      </Slide>

      {isLoading && (
        <>
          <Typography
            variant="h3"
            component="h2"
            sx={{ color: "#000" }}
            align="center"
            gutterBottom
          >
            {t("checkingId")}
          </Typography>
          <IconButton aria-label="" edge="end">
            <CircularProgress
              size={25}
              sx={{ color: "#68F3CB" }}
              color="success"
            />
          </IconButton>
        </>
      )}

      {isVerified && (
        <>
          <Typography
            variant="body2"
            component="p"
            sx={{ color: "#000" }}
            align="center"
            gutterBottom
          >
            {t("ifInstalled")}
          </Typography>
          <Button
            variant="outlined"
            color="secondary"
            component="a"
            href="https://app.mingle.sport/"
          >
            {t("openTheApp")}
          </Button>
        </>
      )}
    </SignInUpContainer>
  );
};
export default VerifyEmail;
