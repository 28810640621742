import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import {
  LogoMingle,
  LogoTextMingle,
} from "../components/brandlibrary/MingleIcons";
import { Button, Stack, Theme, Toolbar, useMediaQuery } from "@mui/material";
import downloadAppStore from "../images/download-app-store.svg";
import googlePlayBadge from "../images/google-play-badge.png";

import { useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { useTranslation } from "react-i18next";

const GetMingle: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { boot, show } = useIntercom();
  boot();
  const matchesMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <div
      style={{
        width: "100%",
        minHeight: matchesMobile ? "100vh" : "100vh",
        background: "#F7F7F8",
      }}
    >
      <Toolbar
        sx={{
          backgroundColor: "transparent",
          height: "80px",
        }}
      >
        <div
          style={{
            backgroundColor: "#68F3CB",
            borderRadius: matchesMobile ? "12px" : "16px",
            height: matchesMobile ? "32px" : "48px",
            width: matchesMobile ? "32px" : "48px",
            padding: matchesMobile ? "6px" : "12px",
            lineHeight: matchesMobile ? "20px" : "24px",
            marginRight: "16px",
            marginLeft: 0,
          }}
        >
          <LogoMingle
            sx={{
              color: "#0B1ECF",
              fontSize: matchesMobile ? "20px" : "24px",
              lineHeight: matchesMobile ? "20px" : "24px",
              textDecoration: "none",
            }}
          />
        </div>
        {!matchesMobile && (
          <LogoTextMingle
            width="181"
            height="29"
            sx={{
              color: "#000",
              height: "29px",
              fontSize: "181px",
              margin: "0 20px 0 0",
              textDecoration: "none",
            }}
          />
        )}
      </Toolbar>

      <Container maxWidth="xl">
        <Stack
          spacing={3}
          alignContent="center"
          alignSelf="center"
          alignItems="center"
          justifyContent="center"
          sx={{
            minHeight: matchesMobile ? "200px" : "calc(100vh - 80px)",
          }}
        >
          <Stack
            spacing={8}
            direction={matchesMobile ? "column" : "row"}
            sx={{}}
          >
            <Stack
              spacing={2}
              alignContent="center"
              alignSelf="center"
              alignItems="center"
              // justifyContent="center"
              sx={{
                background: "#fff",
                borderRadius: "32px",
                padding: "30px 70px",
                width: "100%",
                minHeight: matchesMobile ? "100%" : "300px",
              }}
            >
              <Typography
                variant="h3"
                component="h3"
                textAlign="center"
                sx={{
                  color: "#000",
                  fontWeight: "700",
                  maxWidth: "750px",
                }}
              >
                {t("getMingle.title")}
              </Typography>
              <Typography
                variant="body1"
                textAlign="center"
                sx={{ color: "#000" }}
              >
                {t("getMingle.isAvailable")}
              </Typography>
              <a
                href="https://apps.apple.com/us/app/mingle-sport-grassroots-app/id1583591186"
                target="_blank"
              >
                <img
                  src={downloadAppStore}
                  style={{ height: "auto", width: "180px" }}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=sport.mingle.android.app"
                target="_blank"
              >
                <img
                  src={googlePlayBadge}
                  style={{ height: "auto", width: "180px" }}
                />
              </a>
              {isMobile && isIOS && (
                <Button
                  variant="outlined"
                  color="secondary"
                  component="a"
                  target="_blank"
                  href="https://apps.apple.com/us/app/mingle-sport-grassroots-app/id1583591186"
                  sx={{
                    margin: "auto",
                    borderWidth: "2px",
                    whiteSpace: "nowrap",
                    "&:hover": { borderWidth: "2px" },
                  }}
                >
                  {t("getMingle.openApp")}
                </Button>
              )}
              {isMobile && isAndroid && (
                <Button
                  variant="outlined"
                  color="secondary"
                  target="_blank"
                  component="a"
                  href="https://play.google.com/store/apps/details?id=sport.mingle.android.app"
                  sx={{
                    margin: "auto",
                    borderWidth: "2px",
                    whiteSpace: "nowrap",
                    "&:hover": { borderWidth: "2px" },
                  }}
                >
                  {t("getMingle.openApp")}
                </Button>
              )}
            </Stack>

            <Stack
              spacing={2}
              alignContent="center"
              alignSelf="center"
              alignItems="center"
              // justifyContent="center"
              sx={{
                background: "#fff",
                borderRadius: "32px",
                padding: "30px 70px",
                width: "100%",
                minHeight: matchesMobile ? "100%" : "300px",
              }}
            >
              <Typography
                variant="h3"
                component="h3"
                textAlign="center"
                sx={{
                  color: "#000",
                  fontWeight: "700",
                  maxWidth: "750px",
                }}
              >
                {t("getMingle.needHelp")}
              </Typography>
              <Typography
                variant="body1"
                textAlign="center"
                sx={{ color: "#000" }}
              >
                {t("getMingle.needHelpSubtitle")}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                sx={
                  {
                    // borderWidth: "2px",
                    // whiteSpace: "nowrap",
                    // "&:hover": { borderWidth: "2px" },
                  }
                }
                onClick={() => {
                  show();
                }}
              >
                {t("getMingle.askSupport")}
              </Button>
            </Stack>
          </Stack>

          <div style={{ textAlign: "center", margin: "40px" }}>
            <Typography
              variant="body1"
              textAlign="center"
              gutterBottom
              sx={{ color: "#000" }}
            >
              {t("getMingle.moreInformation")}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              component="a"
              href="https://mingle.sport"
              sx={{
                margin: "auto",
                borderWidth: "2px",
                whiteSpace: "nowrap",
                "&:hover": { borderWidth: "2px" },
              }}
            >
              {t("getMingle.gotoMingle")}
            </Button>
          </div>
        </Stack>
      </Container>
    </div>
  );
};
export default GetMingle;
