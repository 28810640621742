import * as React from "react";
import { Route, Routes } from "react-router-dom";

import BottomBar from "./components/BottomBar";

import VerifyEmail from "./pages/VerifyEmail";
import GetMingle from "./pages/GetMingle";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<BottomBar />}>
        <Route index element={<GetMingle />} />
        <Route path="/verify/:verificationId" element={<VerifyEmail />} />
      </Route>
    </Routes>
  );
}
