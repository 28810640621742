import React from "react";

export type LocationProps = {
  state: {
    from: Location;
  };
  pathname: string;
};
export const baseWebApiUrl: string | undefined =
  process.env.REACT_APP_BASE_WEBAPPI_URL;
export interface ResetPasswordCredentials {
  changePasswordId: string;
  newPassword: string;
}
export interface ForgotPasswordCredentials {
  username: string;
}
