import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";

import {
  Avatar,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Stack,
  Theme,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { Outlet, useSearchParams } from "react-router-dom";
import { LogoMingle } from "./brandlibrary/MingleIcons";
import { useTranslation } from "react-i18next";
import MuiMenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ReactCountryFlag from "react-country-flag";
import Container from "@mui/material/Container";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
  top: "auto",
  bottom: 0,
  minHeight: "48px",
  background: "#fff",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const Content: React.FC<any> = ({ children, ...props }) => {
  const theme = useTheme();

  const { t, i18n } = useTranslation();
  const matchesMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  let [searchParams, setSearchParams] = useSearchParams();
  let [lng, setLng] = useSearchParams();
  const changeLanguage = (lng: string) => {
    // i18n.changeLanguage(lng);
    setSearchParams({ lng });
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  React.useEffect(() => {
    i18n.changeLanguage(searchParams.get("lng") || i18n.language);
  }, [searchParams]);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    lang: string
  ) => {
    console.log(i18n.language);
    console.log(i18n.languages);
    i18n.changeLanguage(lang);
    setAnchorEl(null);
  };

  const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(1),
      display: "flex",
      width: "100%",
    },
  }));

  const openLangMenu = Boolean(anchorEl);

  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignContent="center"
      alignItems="center"
    >
      <Outlet />

      {/*<AddToHomeScreen delayNotify={500} skipFirstVisit={false} />*/}

      <AppBar
        position="fixed"
        open={true}
        color="secondary"
        sx={{ zIndex: 999, minHeight: "90px", display: "block" }}
      >
        <Toolbar
          sx={{
            pr: "20px",
            pt: "8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
            color: "#000",
          }}
        >
          <div
            style={{
              backgroundColor: "#68F3CB",
              borderRadius: matchesMobile ? "12px" : "16px",
              height: matchesMobile ? "32px" : "48px",
              width: matchesMobile ? "32px" : "48px",
              padding: matchesMobile ? "6px" : "12px",
              lineHeight: matchesMobile ? "20px" : "24px",
              marginRight: "16px",
              marginLeft: 0,
            }}
          >
            <LogoMingle
              sx={{
                color: "#0B1ECF",
                fontSize: matchesMobile ? "20px" : "24px",
                lineHeight: matchesMobile ? "20px" : "24px",
                textDecoration: "none",
              }}
            />
          </div>

          {/*<div>{t("description.part2")}</div>*/}
          {/*<button type="button" onClick={() => changeLanguage("nl")}>*/}
          {/*  nl*/}
          {/*</button>*/}
          {/*<button type="button" onClick={() => changeLanguage("en")}>*/}
          {/*  en*/}
          {/*</button>*/}

          <>
            <List
              component="nav"
              aria-label="Select Language"
              sx={{ marginRight: "60px" }}
            >
              <ListItem
                button
                id="language-button"
                aria-haspopup="listbox"
                onClick={handleClickListItem}
                sx={{
                  maxWidth: "242px",
                  borderRadius: "8px",

                  "&:hover": {
                    background: "rgba(11, 30, 207, 0.06)",
                  },
                }}
              >
                <ListItemIcon>
                  <Avatar>
                    <ReactCountryFlag
                      countryCode={i18n.language === "en" ? "gb" : "nl"}
                      svg
                      style={{
                        width: "60px",
                        height: "60px",
                      }}
                    />
                  </Avatar>
                </ListItemIcon>
                <ListItemText
                  secondary={i18n.language === "en" ? "English" : "Nederlands"}
                />
                <ListItemIcon>
                  <KeyboardArrowDownIcon
                    sx={{
                      fontSize: "1.875rem",
                      color: "#000",
                      marginLeft: "16px",
                    }}
                  />
                </ListItemIcon>
              </ListItem>
            </List>

            <Menu
              id="language-menu"
              anchorEl={anchorEl}
              open={openLangMenu}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "lock-button",
                role: "listbox",
              }}
              sx={{
                "& .MuiPopover-paper": {
                  boxShadow: "0px 3px 12px rgba(11, 30, 207, 0.1)",
                },
                "& .MuiMenu-list": {
                  background: "#fff",
                  padding: "8px",
                  borderRadius: "8px",
                  boxShadow: "none",
                },
              }}
            >
              <MenuItem
                value="nl"
                selected={i18n.language === "nl"}
                onClick={(event) => handleMenuItemClick(event, "nl")}
                sx={{
                  maxWidth: "242px",
                  borderRadius: "8px",
                  background: "#fff",
                  marginBottom: "2px",
                  "&:hover": {
                    background: "rgba(11, 30, 207, 0.06)",
                  },
                  "&.Mui-selected": {
                    background: "rgba(11, 30, 207, 0.06)",
                  },
                }}
              >
                <ListItemIcon>
                  <Avatar>
                    <ReactCountryFlag
                      countryCode="NL"
                      svg
                      style={{
                        width: "60px",
                        height: "60px",
                      }}
                      title="Nederlands"
                    />
                  </Avatar>
                </ListItemIcon>
                <ListItemText
                  secondary="Nederlands"
                  sx={{ padding: "0 10px", fontSize: "0.875rem" }}
                />
              </MenuItem>
              <MenuItem
                value="nl"
                selected={i18n.language === "en"}
                onClick={(event) => handleMenuItemClick(event, "en")}
                sx={{
                  maxWidth: "242px",
                  borderRadius: "8px",
                  background: "#fff",
                  marginBottom: "2px",
                  "&:hover": {
                    background: "rgba(11, 30, 207, 0.06)",
                  },
                  "&.Mui-selected": {
                    background: "rgba(11, 30, 207, 0.06)",
                  },
                }}
              >
                <ListItemIcon>
                  <Avatar>
                    <ReactCountryFlag
                      countryCode="GB"
                      svg
                      style={{
                        width: "60px",
                        height: "60px",
                      }}
                      title="English"
                    />
                  </Avatar>
                </ListItemIcon>
                <ListItemText
                  secondary="English"
                  sx={{ padding: "0 10px", fontSize: "0.875rem" }}
                />
              </MenuItem>
            </Menu>
          </>
        </Toolbar>
      </AppBar>
    </Stack>
  );
};
// loading component for suspense fallback
const Loader = () => (
  <Container>
    <Stack
      direction="column"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      sx={{ minHeight: "100vh" }}
    >
      <IconButton aria-label="" edge="end">
        <CircularProgress size={25} sx={{ color: "#68F3CB" }} color="success" />
      </IconButton>
    </Stack>
  </Container>
);
export default function BottomBar() {
  return (
    <React.Suspense fallback={<Loader />}>
      <Content />
    </React.Suspense>
  );
}
