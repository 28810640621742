import axios from "axios";
import { baseWebApiUrl } from "./api.interfaces";

export const postVerify = (
  verificationId: string | undefined
): Promise<any> => {
  return axios({
    method: "POST",
    url: `${baseWebApiUrl}/User/VerifyEmail`,
    data: { verificationId: verificationId },
  })
    .then<any>((res) => res.data)
    .catch(function (error) {
      return Promise.reject(error);
    });
};
